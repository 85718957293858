import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import {PhoneOutlined, EnvironmentOutlined, FlagOutlined } from '@ant-design/icons';
import { MdOutlinePhone } from "react-icons/md";
import Flags from "country-flag-icons/react/3x2";

import Empty from "antd"

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,

  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>

<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

  
  

  <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
    <MdOutlinePhone style={{ fontSize: '2em', color: ' #18216d', marginRight: '5px' ,width:'20px' , height:'20px' }} />
    <Para style={{ fontSize: '1.2em', fontFamily: 'initial' }}>07466445212</Para>
  </div>


  <div style={{ flex: 1, marginRight: '15px', display: 'flex', alignItems: 'center' }}>
    <EnvironmentOutlined style={{ fontSize: '2em', color: ' #18216d', marginRight: '5px' , width:'20px' , height:'20px'}} />
    <Para style={{ fontSize: '1.2em', fontFamily: 'initial' }}>2131 Elk Street</Para>
  </div>



  <div style={{ flex: 1, marginRight: '15px', display: 'flex', alignItems: 'center' }}>
    <Flags.GB style={{ fontSize: '2em', marginRight: '5px', width:'20px' , height:'20px'}} />
    <Para style={{ fontSize: '1.2em', fontFamily: 'initial' }}>London_England</Para>
  </div>
</div>



       {/* <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
            
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/lasha-kakabadze/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              />
              <a
                href="https://ko-fi.com/Y8Y7H8BNJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  height="36"
                  style={{ border: 0, height: 36 }}
                  src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"
                  alt="Buy Me a Coffee at ko-fi.com"
                />
              </a>
            </FooterContainer>
          </Row>
        </Container>
  </Extra>  */}
  </>
  );
};

export default withTranslation()(Footer);
